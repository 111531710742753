
/**
 * 作用：挂载app回调方法
 * @param {*} appMethodName app方法名字
 * @param {*} callback 回调方法名字
 */
export function getAppMethodListener(appMethodName) {
  return new Promise((resolve) => {
    try {
      window[`${appMethodName}`] = (res) => {
        resolve(res)
      }
    } catch (error) {
      resolve()
    }
  })
}

/**
 * 作用：获取 authorization
 */
export async function getAuthorization() {
  try {
    window.webkit.messageHandlers.getAuthorization.postMessage('11')
    return await getAppMethodListener('getAuthorization')
  } catch (error) {
    console.log(error);
  }
}

/**
 * 作用：跳转 选择用户 新建运动规划
 */
export function userPlanList() {
  try {
    window.webkit.messageHandlers.UserPlanBPMUserViewVC.postMessage('11')
  } catch (error) {
    console.log(error);
  }
}

/**
 * 作用：跳转 新建运动规划
 * @param {*} userId
 */
export function createUserPlan(userId) {
  try {
    window.webkit.messageHandlers.UserPlanBPMVC.postMessage({
      userId
    })
  } catch (error) {
    console.log(error);
  }
}

/**
 * 作用：跳转 方案详情
 * @param {*} userTeachingTemplateId
 */
export function goUserPlanDetail(userTeachingTemplateId) {
  try {
    window.webkit.messageHandlers.UserPlanBPMTemplateVC.postMessage({
      userTeachingTemplateId
    })
  } catch (error) {
    console.log(error);
  }
}

