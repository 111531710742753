<template>
  <div :class="['item row-start-start', type === 'phone' ? 'f20' : 'f14']" :key="item.goodsId">
    <img :class="['item-img flex-none', size === 'small' ? 'small' : '', type === 'phone' ? 'phone' : '']" :src="item.goodsImg" alt="" />
    <div :class="[isCanAdd ? 'row-between-center' : 'row-between-end', 'item-left-box']">
      <div class="item-left">
        <h3 :class="[type === 'phone' ? 'f30' : 'f18']">{{ item.goodsName }}</h3>
        <p class="color3">{{ item.hint }}</p>
        <p v-if="item.productTags" class="tips">{{ item.productTags }}</p>
        <div class="price-line row-start-end">
          <span class="dl fw6">¥</span>
          <span :class="['price fw6', type === 'phone' ? 'f32' : 'f20']">{{ item.salePrice }}</span>
          <span v-if="item.displayOriginPrice" :class="['ori-price', type === 'phone' ? 'f20' : '']">¥{{ item.originPrice }}</span>
          <span v-if="item.plugTags && size !== 'small'" :class="['label f12', type === 'phone' ? 'f20' : 'f12']">{{ item.plugTags }}</span>
        </div>
      </div>

      <div v-if="isCanAdd" class="stepper row-start-center fw6">
        <p @click="cartMinus(item, $event)" class="minus row-center-center"><van-icon name="minus" /></p>
        <p class="num f26 row-center-center">{{ num_ }}</p>
        <p @click="cartPlus(item, $event)" class="plus row-center-center"><van-icon name="plus" /></p>
      </div>
      <div v-else class="stepper pos row-start-center fw6">
        <p :class="['num-2 row-center-center', size === 'small' ? 'f22' : 'f32']">x{{ num_ }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    num: {
      type: Number,
      default: 0,
    },
    isCanAdd: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      num_: 0,
    }
  },
  watch: {
    num(v) {
      this.num_ = v
    },
  },
  created() {
    this.num_ = this.num
  },
  methods: {
    cartMinus(item, ev) {
      if (this.num_ === 0) return
      let num = --this.num_
      this.$emit('cartChange', { ev, item: this.item, num })
    },
    cartPlus(item, ev) {
      let num = ++this.num_
      this.$emit('cartChange', { ev, item: this.item, num, type: 'PLUS' })
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  padding: 16px 14px 16px 14px;
  background: white;
  border-radius: 8px;
  .item-img {
    width: 170px;
    height: 124px;
    border-radius: 6px;
    object-fit: contain;
    object-position: top;
    &.small {
      width: 137px;
      height: 111px;
    }
    &.phone {
      width: 200px;
      height: 176px;
    }
  }

  .item-left-box {
    flex-grow: 1;
    padding-left: 20px;
    position: relative;
  }
  .item-left {
    height: 100%;
    & > p {
      margin-top: 8px;
    }
    & > h3 {
      color: #242831;
    }
    .f14 {
      line-height: 14px;
    }
    .tips {
      display: inline-block;
      color: #ffffff;
      padding: 2px 8px;
      border-radius: 4px;
      background: linear-gradient(270deg, #fc2c11 0%, #fc7411 100%);
    }
  }
  .stepper {
    margin: 0 10px 0 0;
    &.pos {
      position: absolute;
      right: 0;
      bottom: 0px;
      margin: 0;
    }
    .plus,
    .minus {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px solid;
    }
    .plus {
      border-color: #ffde00;
      background-color: #ffde00;
    }
    .minus {
      border-color: #000;
    }
    .num {
      width: 52px;
    }
  }
}
.price-line {
  margin-top: 4px;

  .dl {
    color: #ee4832;
    //margin-right: 4px;
  }

  .price {
    color: #ee4832;
    margin-right: 6px;
    position: relative;
    top: 2px;
  }

  .ori-price {
    text-decoration: line-through;
    color: #6c727a;
  }
  .label {
    background: #ee4832;
    padding: 2px 5px;
    color: white;
    border-radius: 2px;
    margin: 0 0 0 12px;
  }
}
</style>
